import React from 'react'
import { Advert, Header, HeaderHero, Loading, ProductsBanner, SmallScreenHeader } from '../../components';
import { useParams } from 'react-router-dom';
import { useFetchData } from '../../hooks';
import { APPDATAURLS } from '../../utils/APPADATAURLS';

const ShopProductsPage = () => {
    const {companyName,companyID} = useParams();
    const {loading,error,message,results} = useFetchData(`${APPDATAURLS.companyProducts}${companyID}`);
  return (
    <div
    className="body"
    >
        <Header/>
        
        <div
        className="body-wrapper"
        >
            <SmallScreenHeader title={""} backButtonShown />
            <Advert/>
            <HeaderHero heading={companyName} viewAll={false} />
            {
                error?
                <div>
                    <p>{message}</p>
                </div>:
                loading?
                <Loading.ProductsLoading/>:
                results.length>0?
                <ProductsBanner data={{data:results,loading,message,error}} />:
                <div>
                    <p>Their is nothing here.</p>
                </div>
            }
        </div>
    </div>
  )
}

export default ShopProductsPage;