import React, { useEffect, useState } from 'react'

const RatingsCard = ({ratings}) => {
    const [productRatings,setProductRatings] = useState({
        1:0,
        2:0,
        3:0,
        4:0,
        5:0
    });

    const getKey = (keySearch)=>{
        for(let key in productRatings){
            console.log(key);
            if(key == keySearch){
                return true;
            }
        }
        return false;
    }

    useEffect(()=>{
        ratings.filter((rating)=>{
            if(getKey(rating.stars)){
                setProductRatings(productRatings[rating.stars]+1);
            }
        })

        console.log(productRatings);
    },[])
  return (
    <div>

    </div>
  )
}

export default RatingsCard;