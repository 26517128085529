import React from "react";
import { Header, SmallScreenHeader } from "../../components";
import { CUSTOMER_CARE_NUMBER } from "../../utils/constants";

const AboutUs = () => {
  return (
    <div className="body">
      <Header />
      <div className="body-wrapper">
        <SmallScreenHeader backButtonShown={true} title={"About us"} />
        <header>
          <h1>About Us</h1>
        </header>
        <main>
          <section class="intro">
            <h2>Our Story</h2>
            <p>
              Welcome to SAVOWAREHOUSE LIMITED, your number one source for
              ecommerce products. We're dedicated to giving you the very best of
              ecommerce products, with a focus on quality, customer service, and
              uniqueness.
            </p>
            <p>
              Founded in 2022, SAVOWAREHOUSE LIMITED has come a long way from
              its beginnings in a home office. When
              we first started out, our passion for eco-friendly
              products drove us to start our own business.
            </p>
          </section>

          <section class="mission">
            <h2>Our Mission</h2>
            <p>
              Our mission is to provide high-quality ecommerce products that not
              only meet the needs of our customers but also contribute to a
              sustainable and eco-friendly lifestyle. We believe in offering
              products that we would use ourselves, ensuring that every item we
              sell is crafted with care and attention to detail.
            </p>
          </section>

          <section class="values">
            <h2>Our Values</h2>
            <ul>
              <li>
                <strong>Quality:</strong> We prioritize quality in everything we
                do, from the products we offer to the service we provide.
              </li>
              <li>
                <strong>Customer Satisfaction:</strong> Our customers are at the
                heart of our business. We strive to exceed their expectations
                with every purchase.
              </li>
              <li>
                <strong>Integrity:</strong> We operate with honesty and
                integrity in every aspect of our business.
              </li>
              <li>
                <strong>Sustainability:</strong> We are committed to sustainable
                practices and products that benefit both people and the planet.
              </li>
            </ul>
          </section>

          <section class="team">
            <h2>Meet the Team</h2>
            <p>
              Our team is made up of passionate individuals who are committed to
              bringing you the best shopping experience. From product selection
              to customer support, we work together to ensure that 
              SAVOWAREHOUSE remains your go-to destination for ecommerce products.
            </p>
          </section>

          <section class="contact">
            <h2>Contact Us</h2>
            <p>
              If you have any questions or comments, please don't hesitate to
              contact us at {CUSTOMER_CARE_NUMBER}. We are always here to help
              and would love to hear from you!
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default AboutUs;
