import React, { useState, useEffect } from "react";
import {
  BottomTabs,
  CustomButton,
  Footer,
  Header,
  SmallScreenHeader,
} from "../../components";
import { FaChevronRight, FaEdit, FaPen } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import "./profile.css";
import { useContext } from "react";
import { UserContext } from "../../contexts";
import { Auth } from "..";
import { APIURL, APPDATAURLS, BASEURL } from "../../utils/APPADATAURLS";
import { Link, useNavigate } from "react-router-dom";
import { useGet } from "../../hooks";
import { logo } from "../../assets";
import { AiFillCamera } from "react-icons/ai";

function Profile() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [showEditModal, setShowEditModal] = useState(0);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(0);
  const [userData, setUserData] = useContext(UserContext);

  const logout = () => {
    setUserData({ loggedIn: false, data: {} });
    localStorage.removeItem("user");
    navigate("/");
  };
  const [image, setImage] = useState({
    encodedImage: null,
  });

  const [orders, setOrders] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const [data, setData] = useState({
    loading: false,
    error: false,
    message: "",
    orders: [],
    addresses: [],
  });

  const [loading, setLoading] = useState({
    orders: true,
    addresses: true,
  });

  const [user, setUser] = useState({
    firstName: userData.data.firstName,
    lastName: userData.data.lastName,
    email: userData.data.email,
    phoneNumber: userData.data.phoneNumber,
    oldPassword: "",
    newPassword: "",
  });

  const getOrders = () => {
    setData({ ...data, loading: true });
    setLoading({ ...loading, orders: true });

    fetch(`${APPDATAURLS.userOrders}${userData.data.id}`, {
      method: "GET",
    })
      .then((req) => req.json())
      .then((response) => {
        setLoading({ ...loading, orders: false });
        setData({ ...data, loading: false, orders: response.data });
        setOrders(response.data);
      })
      .catch((e) => {
        setLoading({ ...loading, orders: false });
        console.log(e);
      });
  };

  const getAddresses = () => {
    setLoading({ ...loading, addresses: true });
    fetch(`${APPDATAURLS.customerAddresses}${userData.data.id}`, {
      method: "GET",
    })
      .then((req) => req.json())
      .then((response) => {
        setLoading({ ...loading, addresses: false });
        setData({ ...data, loading: false, addresses: response.data });
        setAddresses(response.data);
      })
      .catch((e) => {
        setLoading({ ...loading, addresses: false });
        console.log(e);
      });
  };

  const userAddresses = useGet(
    `${APPDATAURLS.customerAddresses}${userData.data.id}`
  );

  const handlePickImage = async (e) => {
    setImage({ encodedImage: await toBase64(e.target.files[0]) });
  };
  const [upload, setUpload] = useState({
    loading: false,
    error: false,
    message: "",
  });
  const uploadImage = async () => {
    setUpload({
      loading: true,
      message: "",
      error: false,
    });
    let formData = new FormData();
    formData.append("set-profile-image", "set");
    formData.append("userID", userData.data.id);
    formData.append("profile-image", image.encodedImage);
    const token = null;
    fetch(APIURL, {
      method: "POST",
      body: formData,
      /*headers:{
            "Authorization":`Bearer ${token}`
          },
          credentials:"include"*/
    })
      .then((req) => req.json())
      .then((response) => {
        setImage({
          encodedImage: null,
        });
        setUpload({
          loading: false,
          message: response.message,
          error: response.error,
        });
        setUserData({ loggedIn: true, data: response.data.info });
        localStorage.removeItem("user");
        localStorage.setItem(
          "user",
          JSON.stringify({ loggedIn: true, data: response.data.info })
        );
      })
      .catch((e) => {
        console.log(e);
        setUpload({
          loading: false,
          message: "Profile image not uploaded. Try again later.",
          error: true,
        });
      });
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        //console.log(reader.result);
        //out = reader.result;
        resolve(reader.result);
      };

      reader.onerror = function (err) {
        reject(err);
      };
    });

  const [errors, setErrors] = useState({
    state: false,
    loading: false,
    message: "",
  });
  const updateProfile = () => {
    setErrors({
      state: false,
      loading: true,
      message: "",
    });
    let formData = new FormData();
    formData.append("update-profile", user.name);
    formData.append("firstName", user.firstName);
    formData.append("lastName", user.lastName);
    formData.append("email", user.email);
    formData.append("phoneNumber", user.phoneNumber);
    formData.append("id", userData.data.id);
    fetch(APIURL, {
      method: "POST",
      body: formData,
    })
      .then((req) => req.json())
      .then((res) => {
        console.log(res);

        localStorage.removeItem("user");
        let data = { loggedIn: true, data: res.data };
        setUserData(data);
        localStorage.setItem("user", JSON.stringify(data));
        setErrors({
          state: res.error,
          loading: false,
          message: res.message,
        });

        if (!res.error) {
          setTimeout(() => {
            setShowEditModal(false);
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
        setErrors({
          state: true,
          loading: false,
          message: "Ooops, an error occurred. Try again later.",
        });
      });
  };

  const [errors1, setErrors1] = useState({
    state: false,
    loading: false,
    message: "",
  });
  const updatePassword = () => {
    setErrors1({
      state: false,
      loading: true,
      message: "",
    });
    let formData = new FormData();
    formData.append("update-password", user.name);
    formData.append("oldPassword", user.oldPassword);
    formData.append("newPassword", user.newPassword);
    formData.append("id", userData.data.id);
    fetch(APIURL, {
      method: "POST",
      body: formData,
    })
      .then((req) => req.json())
      .then((res) => {
        console.log(res);
        setErrors1({
          state: res.error,
          loading: false,
          message: res.message,
        });
        if (!res.data.error) {
          setTimeout(() => {
            setShowEditPasswordModal(false);
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
        setErrors1({
          state: true,
          loading: false,
          message: "Ooops, an error occurred. Try again later.",
        });
      });
  };

  useEffect(() => {
    window.scroll(0, 0);
    getOrders();
    //getAddresses();

    if (localStorage.getItem("user")) {
      let u = JSON.parse(localStorage.getItem("user"));
      setUser({
        firstName: u.data.firstName,
        lastName: u.data.lastName,
        email: u.data.email,
        phoneNumber: u.data.phoneNumber,
      });
    }
  }, [userData.loggedIn]);

  console.log(loading);
  return (
    <>
      {userData.loggedIn ? (
        <div className="body">
          <Header />

          <Modal
            show={showEditModal}
            onBackdropClick={() => setShowEditModal(false)}
            size="xl"
            onHide={() => setShowEditModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit profile info</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {errors.message.length > 0 ? (
                <label
                  style={{
                    color: errors.state ? "red" : "green",
                  }}
                >
                  {errors.message}
                </label>
              ) : errors.loading ? (
                <label>Loading...</label>
              ) : (
                <></>
              )}
              <label>First Name</label>
              <input
                value={user.firstName}
                onChange={(e) =>
                  setUser({ ...user, firstName: e.target.value })
                }
              />
              <label>Last Name</label>
              <input
                value={user.lastName}
                onChange={(e) => setUser({ ...user, lastName: e.target.value })}
              />
              <label>Email</label>
              <input
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
              <label>Phone number</label>
              <input
                value={user.phoneNumber}
                onChange={(e) =>
                  setUser({ ...user, phoneNumber: e.target.value })
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={() => {
                  console.log(user);
                  updateProfile();
                }}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showEditPasswordModal}
            onBackdropClick={() => setShowEditPasswordModal(false)}
            size="xl"
            onHide={() => setShowEditPasswordModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit password</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {errors1.message.length > 0 ? (
                <label
                  style={{
                    color: errors1.state ? "red" : "green",
                  }}
                >
                  {errors1.message}
                </label>
              ) : errors1.loading ? (
                <label>Loading...</label>
              ) : (
                <></>
              )}
              <label>Old password</label>
              <input
                value={user.oldPassword}
                onChange={(e) =>
                  setUser({ ...user, oldPassword: e.target.value })
                }
              />
              <label>New password</label>
              <input
                value={user.newPassword}
                onChange={(e) =>
                  setUser({ ...user, newPassword: e.target.value })
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={() => {
                  console.log(user);
                  updatePassword();
                }}
              >
                Update password
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="body-wrapper">
            <SmallScreenHeader
              backButtonShown={false}
              title={"My Profile"}
              titleCentered={true}
            />

            <div
              className="profile_bg"
              
            >
              <img
                src={
                  image.encodedImage
                    ? image.encodedImage
                    : userData.data.image
                    ? `${BASEURL}/${userData.data.image}`
                    : logo
                }
                alt="profile image"
              />
            </div>
            <div
              style={{
                position: "relative",
              }}
              className="user-profile"
            >
              <div className="profile-image">
                <img
                  src={
                    image.encodedImage
                      ? image.encodedImage
                      : userData.data.image
                      ? `${BASEURL}/${userData.data.image}`
                      : logo
                  }
                  alt="profile image"
                />
                <div className="select-file">
                  <AiFillCamera className="camera"/>
                  <input type="file" onChange={handlePickImage} />

                </div>
              </div>
              <div className="name-email">
                <label className="name">
                  {userData.data.firstName} {userData.data.lastName}
                </label>
                <label className="email">{userData.data.email}</label>
              </div>
              <FaEdit
                style={{
                  color: "var(--app-color)",
                  position: "absolute",
                  top: 0,
                  right: 10,
                  zIndex:2,
                }}
                size={25}
                onClick={() => setShowEditModal(true)}
              />
            </div>
            <div
              style={{
                width: "98%",
                margin: "1%",
                display: image.encodedImage ? "flex" : "none",
                flexDirection: "row",
                gap: 10,
              }}
            >
              <CustomButton
                disabled={upload.loading}
                title={upload.loading ? "Uploading..." : "Cancel"}
                pressAction={() => {
                  setImage({ ...image, encodedImage: null });
                }}
                display={image.encodedImage ? "flex" : "none"}
                backgroundColor={"var(--app-color)"}
                width={"30%"}
              />
              <CustomButton
                disabled={upload.loading}
                title={
                  upload.loading ? "Uploading..." : "Upload profile picture"
                }
                pressAction={uploadImage}
                display={image.encodedImage ? "flex" : "none"}
                backgroundColor={"var(--app-color)"}
              />
            </div>

            <div className="profile-info">
              <Link to={"/profile/orders"} className="profile-buttons">
                <div>
                  <label>Orders</label>
                  <label>
                    {loading.orders
                      ? "Loading orders...."
                      : orders.length > 0
                      ? `You have ${orders.length} orders`
                      : "You have no orders"}
                  </label>
                </div>
                <FaChevronRight />
              </Link>
              {/* {
            !userData.data.deliveryGuyID?
             <Link
            to={"/deliveries"}
            className='profile-buttons'
          >
            <div>
              <label>My deliveries</label>
              <label>
                {orders.length>0?
                `You have ${orders.length} pending deliver${orders.length>1?"ies":"y"}.`:
                "You have no pending deliveries."}
              </label>
            </div>
            <FaChevronRight/>
          </Link>:
          <></>
          } */}

              <Link to={"/favorites"} className="profile-buttons">
                <div>
                  <label>My favorites</label>
                  <label>Favorites</label>
                </div>
                <FaChevronRight />
              </Link>

              <Link to={"/profile/addresses"} className="profile-buttons">
                <div>
                  <label>Shipping addresses</label>
                  <label>
                    {userAddresses.loading
                      ? "Loading adresses..."
                      : userAddresses.results.length > 0
                      ? `You have ${userAddresses.results.length} addresses`
                      : "No addresses"}
                  </label>
                </div>
                <FaChevronRight />
              </Link>
              <Link to={"/profile/addresses"} className="profile-buttons">
                <div>
                  <label>Promo codes</label>
                  <label>No codes</label>
                </div>
                <FaChevronRight />
              </Link>
              <Link to={"/profile/settings"} className="profile-buttons">
                <div>
                  <label>Settings</label>
                  <label></label>
                </div>
                <FaChevronRight />
              </Link>
            </div>

            <div
              style={{
                marginBottom: 70,
                display: "flex",
                gap: 10,
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <CustomButton
                pressAction={() => setShowEditPasswordModal(true)}
                title={"Update password"}
                backgroundColor={"var(--app-color)"}
                width={"98%"}
                borderRadius={5}
                color={"#ffffff"}
              />
              <CustomButton
                pressAction={logout}
                title={"Log out"}
                backgroundColor={"var(--app-danger)"}
                width={"98%"}
                borderRadius={5}
                color={"#ffffff"}
              />
            </div>
          </div>
          <BottomTabs />
          <Footer />
        </div>
      ) : (
        <Auth.LoginPage />
      )}
    </>
  );
}

export default Profile;
