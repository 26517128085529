import React, { useState } from "react";
import "./index.css";
import { FiDelete, FiEdit, FiMapPin, FiTrash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";
import { Button, Modal } from "react-bootstrap";
import { useFetchData } from "../../hooks";
import { APIURL, APPDATAURLS } from "../../utils/APPADATAURLS";

const Address = ({ address, refresh, setRefresh }) => {
  const navigate = useNavigate();
  const [acceptDeletion, setAcceptDeletion] = useState({
    accepted: false,
  });

  const [data, setData] = useState({
    loading: true,
    error: false,
    message: "",
    results: [],
  });

  const [output, setOuput] = useState({});
  const deleteAddress = () => {
    let formData = new FormData();
    formData.append("delete-user-address", "delete");
    formData.append("addressID", address.addressID);
    setData({
      loading: true,
      message: "",
      error: false,
      results: [],
    });
    fetch(`${APIURL}`, {
      method: "POST",
      body: formData,
    })
      .then((req) => req.json())
      .then((res) => {
        setData({
          loading: false,
          message: res.message,
          error: res.error,
          results: res.data,
        });
        if (!res.error) {
          setRefresh(!refresh);
        }
      })
      .catch((e) => {
        console.log(e);
        setData({
          loading: false,
          message: "An error occurred!",
          error: true,
          results: [],
        });
      });
  };
  return (
    <>
      <Modal show={acceptDeletion.accepted} centered>
        <Modal.Body>Do you want to delete address?</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              setAcceptDeletion({ ...acceptDeletion, accepted: false })
            }
          >
            No
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteAddress();
              setAcceptDeletion({ ...acceptDeletion, accepted: false });
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {address.latitude ? (
        <div className="address">
          <label >
            <FiMapPin className="one" style={{marginRight:10}} />
            {address.google_data
              ? address.google_data.results[0].formatted_address
              : "Pinned locaton"}
          </label>
          <CustomButton
            title={"View On Map"}
            backgroundColor={"var(--app-green)"}
            pressAction={() =>
              navigate(`/location/${address.latitude}/${address.longitude}`)
            }
          />

          <FiTrash
            className="delete-address"
            onClick={() =>
              setAcceptDeletion({ ...acceptDeletion, accepted: true })
            }
          />
        </div>
      ) : (
        <div className="address">
          <label className="one">{address?.countyName?.toUpperCase()}</label>
          <label className="two">{address.cityName},</label>
          <label className="three">{address.locationName}, </label>
          <label className="two">{address.description},</label>
          <FiEdit
            className="edit-address1"
            onClick={() =>
              navigate(`/profile/edit-address/${address.addressID}`)
            }
          />
          <FiTrash
            className="delete-address"
            onClick={() =>
              setAcceptDeletion({ ...acceptDeletion, accepted: true })
            }
          />
        </div>
      )}
    </>
  );
};

export default Address;
