import React, { useContext, useEffect, useRef } from 'react';
import { Header, Loading, SmallScreenHeader } from '../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchData } from '../../hooks';
import { APPDATAURLS } from '../../utils/APPADATAURLS';
import { UserContext } from '../../contexts';
import "./index.css";
import { Button, Card, CardGroup, Table } from 'react-bootstrap';

const CheckoutOrder = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const orderRef = useRef();
     const [userData,setUserData] = useContext(UserContext);

    const printOrder = ()=>{
        //orderRef.current.print()
        // window.frames["print_frame"].document.querySelector("#print-order").innerHTML = orderRef.current.innerHTML;
        // window.frames["print_frame"].focus();
        // window.frames["print_frame"].print();

        var printElement = document.createElement('div');
        printElement.style.display = 'none';

        printElement.innerHTML = orderRef.current.innerHTML;

        document.body.appendChild(printElement);
        window.print();
        printElement.parentNode.removeChild(printElement);

        // var newWindow = window.open();
        // newWindow.document.write(orderRef.current.innerHTML);
        // newWindow.print();
    }
    const {results,loading,error,message} = useFetchData(`${APPDATAURLS.userOrder}&userID=${userData.data?.id}&orderID=${id}`);
//console.log(APPDATAURLS.userOrder,results);
  return (
    <div
        className='checkout-body'
    >
      <Header/>
      <SmallScreenHeader title={results?.orderNumber} />
      <div
        className='checkout-wrapper'
        ref={orderRef}
        id='print-order'
      >

        <div className='print-order' >
            {
            error?
            <p>{message}</p>:
            loading?
            <Loading.CategoriesLoading/>:
            <>
            <h1 style={{
                textAlign:"center",
                margin:10
            }} >ORDER</h1>
        <CardGroup style={{display:"flex",flexDirection:"column"}}>
            <Card style={{
                width:"100%",
                display:"flex",
                flexDirection:"row",
                justifyContent:"space-between"
            }}>
                <div>
                    <img
                        src='/assets/images/savo_logo.jpg'
                        style={{
                            width:60,
                            height:60
                        }}
                    />
                    <p>SAVOWAREHOUSE</p>
                </div>

                <p style={{
                    marginRight:5
                }}>ID : {results.orderNumber}</p>
            </Card>
                <h3>Info</h3>
             <Card>
                <p>Order Date : {results.orderDate}</p>
                <p>Payment mode : M-PESA</p>
                <p>Quantity : {results.orderQuantity}</p>
                <p>Total : Ksh.{results.orderTotal}</p>
            </Card>
             <h3>Items</h3>
            <Card style={{width:"auto"}}>
                <Table responsive>
                    <thead>
                        <th>#</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th>Unit price</th>
                        <th>Total (Ksh.)</th>
                    </thead>
                    <tbody>
                       {
                        results.data.map((item,index)=>{
                            return(
                                 <tr>
                                    <td key={index} >{index+1}</td>
                                    <td>{item.productName}</td>
                                    <td>{item.saleOrderQuantity}</td>
                                    <td>{item.productPrice}</td>
                                    <td>{item.saleOrderTotal}</td>
                                </tr>
                            )
                        })
                       }
                    </tbody>
                    <tr style={{fontWeight:"bolder",fontSize:20}}>
                        <td colSpan={4} style={{textAlign:"right"}}>Sub total : </td>
                        <td>Ksh.{results.orderTotal-results.delivery_cost}</td>
                    </tr>
                    <tr style={{fontWeight:"bolder",fontSize:20}}>
                        <td colSpan={4} style={{textAlign:"right"}}>Delievery cost : </td>
                        <td>Ksh.{results.delivery_cost??0}</td>
                    </tr>
                    <tr style={{fontWeight:"bolder",fontSize:20}}>
                        <td colSpan={4} style={{textAlign:"right"}}>Total : </td>
                        <td>Ksh.{results.orderTotal}</td>
                    </tr>
                </Table>
            </Card>


        </CardGroup>
        <Button onClick={printOrder} variant='info' style={{margin:10}} >Print</Button>
        <Button onClick={()=>{
            navigate(`/profile/orders/${id}`);
        }} 
        variant='success'
        >Continue</Button>
        
            
            </>
        }
        </div>
        
        

        </div>

    </div>
  )
}

export default CheckoutOrder;