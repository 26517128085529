import React from 'react'
import { BASEURL } from '../../utils/APPADATAURLS';
import { Link, useNavigate } from 'react-router-dom';
import "./index.css";

const Search = ({isCategory=false,product,setSearchText}) => {
    const navigate = useNavigate();
  return (
    <div
    onClick={(e)=>{
        navigate(isCategory?
          `/shop/${product.categoryName.split("/").join(".")}/${product.categoryID}`:
          `/products/${product.productName.split("/").join(".")}/${product.productID}`);

        setSearchText("");
      }}
    className='search-result'
    >
        <img
        src={`${BASEURL}/${isCategory?product.categoryImage:product.productImage}`}
        />
        <div className='name-and-price' >
            <label>
              {
                isCategory?
                product.categoryName.length>55?`${product.categoryName.substring(0,55)}...`:product.categoryName:
                product.productName.length>55?`${product.productName.substring(0,55)}...`:product.productName
              }
            </label>
            {
              !isCategory?
              <label>Ksh.{product.productPrice}</label>:
              <></>
            }
        </div>
    </div>
  )
}

export default Search;