import React, { useRef } from 'react';
import {AiFillCarryOut, AiOutlineAlipay, AiOutlineDollar, AiOutlineMoneyCollect, AiTwotonePhone} from "react-icons/ai";
import {FaFunnelDollar, FaMoneyCheck} from "react-icons/fa";
import "./index.css";
import { FiPhone } from 'react-icons/fi';

const HeaderBanner = () => {
  return (
    <div className='header_banner1' 
    >
      <div
      style={{
        padding:5
      }}
      >
        <AiOutlineDollar size={40} fill='var(--app-green)' />
        <p style={{
          color:"rgba(0,0,0,1)",
          fontSize:16,
        }}>
          Secure payments
        </p>
      </div>

      <div
      style={{
        padding:5
      }}
      >
        <FaMoneyCheck size={40} fill='var(--app-green)' />
        <p style={{
          color:"rgba(0,0,0,1)",
          fontSize:16,
        }}>
          Instant cashback
        </p>
      </div>

      <div
      style={{
        padding:5
      }}
      >
        <FiPhone size={40} color='var(--app-green)' />
        <p style={{
          color:"rgba(0,0,0,1)",
          fontSize:16,
        }}>
          24/7 online support
        </p>
      </div>
    </div>
  )
}

export default HeaderBanner;



export const RefExample = () => {
  const divRef = useRef();
  const update = ()=>{
    divRef.current.style.backgroundColor = "red";
  }
  return (
    <div ref={divRef} >
      <p>Ref example</p>
      <button onClick={update} >Change background color to red</button>
    </div>
  )
}
