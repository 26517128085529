import React, { useContext, useEffect, useState } from 'react'
import { FaHeart, FaHome, FaSearch, FaShoppingBag, FaShoppingCart, FaUser } from 'react-icons/fa'
import "./index.css";
import { FiHeart, FiHome, FiSearch, FiUser } from 'react-icons/fi';
import {AiFillHome, AiOutlineHome, AiOutlineSearch, AiOutlineShop, AiOutlineShopping, AiOutlineShoppingCart} from "react-icons/ai";
import { Link, useLocation } from 'react-router-dom';
import { CartContext } from '../../contexts/cartContext';
import { UserContext } from '../../contexts';
import { BASEURL } from '../../utils/APPADATAURLS';

const BottomTabs = () => {
    const [cartData,setCartData] = useContext(CartContext);
    const [userData, setUserData] = useContext(UserContext);
    const total = cartData.map(item=>Number(item.quantity)).reduce((prev,curr)=>prev+curr,0);
  const [activeTab,setActiveTab] = useState(null);
  const location = useLocation();
  useEffect(()=>{
    setActiveTab(location.pathname)
  },[location])
  return (
    <div className='bottom-tabs'>
        <Link
        to={"/"}
        className='bottom-tabs-icon'
        >
            <div className={`icon-holder ${activeTab=="/"?"focused":""}`}>
                <AiOutlineHome className='icon'
            color={activeTab=="/"?"var(--app-color)":"black"}
            />
            </div>
            <label className='title' style={{
                display:activeTab=="/"?"flex":"flex",
                color:activeTab=="/"?"var(--app-color)":"black"
            }} >Home</label>
        </Link>
        <Link
        to={"/shop"}
        className='bottom-tabs-icon'
        >
            <div className={`icon-holder  ${activeTab=="/shop"?"focused":""}`}>
            <AiOutlineShop className='icon'
            fill={activeTab=="/shop"?"var(--app-color)":"black"}
            />
            </div>
            <label className='title' style={{
                display:activeTab=="/shop"?"flex":"flex",
                color:activeTab=="/shop"?"var(--app-color)":"black"
            }} >Shop</label>
        </Link>
        <Link
        to={"/search"}
        className='bottom-tabs-icon'
        >
            <div className={`icon-holder ${activeTab=="/search"?"focused":""}`}>
            <FiSearch className='icon'
            color={activeTab=="/search"?"var(--app-color)":"black"}
            />
            </div>
            
            <label className='title' style={{
                display:activeTab=="/search"?"flex":"flex",
                color:activeTab=="/search"?"var(--app-color)":"black"
            }} >Search</label>
        </Link>
        <Link
        to={"/cart"}
        className='bottom-tabs-icon'
        >
            <div
            style={{
                backgroundColor:"var(--app-color)",
                position:"absolute",
                zIndex:100,
                top:activeTab=="/cart"?3:3,
                padding:3,
                left:45,
                borderRadius:10,
                height:18,
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
            }}
            >
                <label style={{
                color:"var(--primary-color)",
                fontSize:16,
                fontWeight:"bold"
            }} >{total}</label>
            </div>
            <div className={`icon-holder  ${activeTab=="/cart"?"focused":""}`}>
            <AiOutlineShoppingCart className='icon'
            fill={activeTab=="/cart"?"var(--app-color)":"black"}
            />
            </div>
            <label className='title' style={{
                display:activeTab=="/cart"?"flex":"flex",
                color:activeTab=="/cart"?"var(--app-color)":"black"
            }} >Cart</label>
        </Link>
        {/* <Link
        to={"/favorites"}
        className='bottom-tabs-icon'
        >
            <FiHeart className='icon'
            color={activeTab=="/favorites"?"var(--app-color)":"black"}
            />
            <label className='title' style={{
                display:activeTab=="/favorites"?"flex":"flex",
                color:activeTab=="/favorites"?"var(--app-color)":"black"
            }} >Favorites</label>
        </Link> */}
        <Link
        to={"/profile"}
        className='bottom-tabs-icon'
        >
            <div className={`icon-holder  ${activeTab=="/profile"?"focused":""}`}>
            {
                userData.loggedIn && userData.data.image?
                <img style={{borderRadius:20}} src={`${BASEURL}/${userData.data.image}`} className='icon'/>:
                <FiUser className='icon'
                    color={activeTab=="/profile"?"var(--app-color)":"black"}
            />
            }
            
            </div>
            <label className='title' style={{
                display:activeTab=="/profile"?"flex":"flex",
                color:activeTab=="/profile"?"var(--app-color)":"black"
            }} >
                {
                    userData.loggedIn?
                    userData.data.firstName:
                    "Profile"
                }
                
            </label>
        </Link>
    </div>
  )
}

export default BottomTabs