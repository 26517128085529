import React, { useContext } from 'react'
import { FiChevronLeft, FiSearch } from 'react-icons/fi'
import "./index.css";
import { CartContext } from '../../contexts/cartContext';
import { Link } from 'react-router-dom';
import { FaSearch, FaShoppingBag, FaShoppingCart } from 'react-icons/fa';
import { AiOutlineShoppingCart } from 'react-icons/ai';
const SmallScreenHeader = ({title,backButtonShown=true,subTitle,backgroundColor="#ffffff",titleCentered=false}) => {
    const [cartData,setCartData] = useContext(CartContext);
    const total = cartData.map(item=>Number(item.quantity)).reduce((prev,curr)=>prev+curr,0);

    const goBack = ()=>{
        return window.history.back();
    }
  return (
    <div

        className='small-screen-header'
        style={{
            width:"100%",
            height:44,
            alignItems:"center",
            gap:10,
            position:"fixed",
            top:0,
            zIndex:110,
            left:0,
            backgroundColor,
            justifyContent:"space-between"
        }}
    >
        
        <div
            onClick={goBack}
            style={{
                width:40,
                height:40,
                borderRadius:5,
                backgroundColor:"var(--app-color)",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                display:backButtonShown?"flex":"none"
            }}
            >
                <FiChevronLeft color='#ffffff'  size={35}/>
        </div>

        <label
        style={{
            textAlign:titleCentered?"center":"left",
            fontWeight:"bold",
            fontSize:20
        }}
        >{title}</label>
        <div style={{
            display:"flex",
            flexDirection:"row",
            gap:10,
            height:"100%",
            alignItems:"center"
        }} >

            <Link to="/search"

            style={{
                position:"relative"
            }}
                >
                <FiSearch size={30} color={"var(--app-color)"} />
        </Link>

        <Link to="/cart"

            style={{
                position:"relative"
            }}
                >
                    <label
                    style={{
                        display:"flex",
                        position:"absolute",
                        top:-13,
                        right:2,
                        color:"var(--app-green)",
                        fontWeight:"700",
                        fontSize:18

                    }}
                    >{total}</label>
                    <AiOutlineShoppingCart size={30} color={"var(--app-color)"} />
            </Link>

        </div>

        
    </div>
  )
}

export default SmallScreenHeader