import React from 'react'
import CustomButton from '../CustomButton';
import "./index.css";
import { FaMinus, FaMinusCircle, FaPlus, FaPlusCircle } from 'react-icons/fa';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { CartContext } from '../../contexts/cartContext';
import { AiOutlineHeart } from 'react-icons/ai';

const AddToBag = ({item,pressed,increase,decrease,params,inCart}) => {
  const [isAdded,setIsAdded] = useState(false);
  const [itemQuantity,setItemQuantity] = useState(0);
  const [cartData,setCartData] = useContext(CartContext);

  const total = cartData.map((cartItem)=>Number(cartItem.productPrice)*cartItem.quantity).reduce((prev,curr)=>prev+curr,0);
  
  const isInBag = ()=>{
    for (let index = 0; index < cartData.length; index++) {
      if(cartData[index].id == item.id){
        setIsAdded(true);
        setItemQuantity(cartData[index].quantity);
        return;
      }
    }
    setIsAdded(false);
  }
  
  useEffect(()=>{
    isInBag();
  },[cartData])
  return (
    <div
    className='add-to-bag-holder'
    >
        <div 
        className='add-subtract'
        style={{
          display:inCart?"flex":"none",
          gap:10
        }}
        >
          <div
          style={{
            fontWeight:"bold"
          }}
          >Quantity</div>
          <div className='add-sub' >
            <FaMinusCircle onClick={decrease} fill='#ffffff' size={24}/>
            <label style={{
              color:"#000000"
            }} >{itemQuantity}</label>
            <FaPlusCircle onClick={increase} fill='#ffffff' size={24} />
          </div>
            
        </div>
       
          <div className='buttons'>
          <CustomButton
            width={"20%"} 
              pressAction={()=>{}} 
              backgroundColor={"#ffffff"} 
              color={"var(--app-color)"} 
              accessoryLeft={<AiOutlineHeart/>}
              />
            <CustomButton 
            width={"78%"} 
              pressAction={pressed} 
              backgroundColor={"#ffffff"} 
              color={"var(--app-color)"} 
              title={"Add To Bag"}/>

            
          </div>

            
        
    </div>
  )
}

export default AddToBag;