import React from "react";
import { Header, SmallScreenHeader } from "../../components";
import "./return_policy.css";

const ReturnPolicy = () => {
  return (
    <div className="body">
      <Header />
      <div className="body-wrapper">
        <SmallScreenHeader backButtonShown={true} title={"Return policy"} />

        <header>
          <h1>Return Policy</h1>
        </header>
        <main className="main">
          <section>
            <h2>Conditions for Return</h2>
            <p>
              We strive to provide the best shopping experience for our
              customers. If you are not satisfied with your purchase, please
              review our return policy conditions:
            </p>
            <ul>
              <li>
                <strong>Product Condition:</strong> Returns are accepted only
                for products that are <strong>undamaged</strong>,{" "}
                <strong>unused</strong>, and in their original packaging with
                all tags and labels intact.
              </li>
              <li>
                <strong>Time Frame:</strong> Return requests must be initiated
                within <strong>2 days</strong> of receiving the product as
                indicated by the delivery tracking information.
              </li>
            </ul>
          </section>

          <section>
            <h2>Non-Returnable Items</h2>
            <ul>
              <li>Products that show signs of damage or use.</li>
              <li>Items without original packaging, tags, or labels.</li>
              <li>Products that are beyond the 2-day return window.</li>
            </ul>
          </section>

          <section>
            <h2>Return Process</h2>
            <ol>
              <li>
                <strong>Step 1:</strong> Contact our Customer Support Team
                within 2 days of delivery to request a return authorization. You
                can reach us via [contact method] or through your account on our
                website.
              </li>
              <li>
                <strong>Step 2:</strong> Provide the order number and reason for
                the return. Please include any relevant photos if the product is
                defective or damaged upon arrival.
              </li>
              <li>
                <strong>Step 3:</strong> Upon approval, we will provide a return
                shipping label. Pack the item securely in its original packaging
                and send it back to us.
              </li>
            </ol>
          </section>

          <section>
            <h2>Refunds</h2>
            <p>
              Once the returned product is received and inspected, we will
              process your refund. Refunds will be issued to the original
              payment method within 5-7 business days after the returned item is
              received and approved.
            </p>
          </section>

          <section>
            <h2>Damaged or Defective Items</h2>
            <p>
              If you receive a damaged or defective product, please contact us
              immediately upon delivery. Such items may be eligible for a
              replacement or refund.
            </p>
          </section>

          <section>
            <h2>Additional Information</h2>
            <p>
              The cost of return shipping may be deducted from your refund
              unless the return is due to an error on our part (e.g., you
              received an incorrect or defective item). We reserve the right to
              deny a refund or exchange if the returned item does not meet the
              above conditions.
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default ReturnPolicy;
