import React from "react";
import { Header, SmallScreenHeader } from "../../components";
import "./contact_us.css";
import { CUSTOMER_CARE_EMAIL, CUSTOMER_CARE_NUMBER } from "../../utils/constants";

const ContactUs = () => {
  return (
    <div className="body">
      <Header />
      <div className="body-wrapper">
        <SmallScreenHeader backButtonShown={true} title={"Contact us"} />

        <header>
          <h1>Contact Us</h1>
        </header>
        <main>
          <section class="contact-info">
            <h2>Get in Touch</h2>
            <p>
              If you have any questions, concerns, or feedback, we would love to
              hear from you. Our team is here to assist you with anything you
              need.
            </p>
          </section>

          <section class="contact-details">
            <h3>Contact Details</h3>
            <p>
              <strong>Phone:</strong>{" "}
              <a className="a" href={`tel:${CUSTOMER_CARE_NUMBER}`}>{CUSTOMER_CARE_NUMBER}</a>
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <a className="a" href={`mailto:${CUSTOMER_CARE_EMAIL}`}>{CUSTOMER_CARE_EMAIL}</a>
            </p>
            <p>
              <strong>Office Hours:</strong> Monday - Saturday
            </p>
          </section>

          <section class="contact-form">
            <h3>Send Us a Message</h3>
            <form action="#" method="post">
              <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div class="form-group">
                <label for="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <button type="submit">Send Message</button>
            </form>
          </section>
        </main>
      </div>
    </div>
  );
};

export default ContactUs;
