import React, { useEffect, useState } from "react";
import { useAppCookies } from "../../hooks";
import { Button } from "react-bootstrap";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const CookieBanner = () => {
  const [shown,setIsShown]=useState(true);
  
  const { addCookie } = useAppCookies();

  useEffect(() => {
    //setIsShown(true);
  });

  return (
    <div className={`${styles.banner} ${shown?styles.shown:styles.hidden}`}>
      <div className={styles.bannerContent}>
        <div className={styles.text}>
        We use cookies to improve Savowarehouse's site. 
        Some cookies are necessary for our website and services to function properly. 
        Other cookies are optional and help personalize your experience, including advertising and analytics. You can consent to all cookies, decline all optional cookies, or manage optional cookies. Without a selection, our default cookie settings will apply. 
        You can change your preferences at any time. 
        To learn more, check out our.
        </div>

        <div className={styles.btns}>
          <Button
          onClick={()=>setIsShown(false)}
          variant="success">Accept Cookies</Button>

          <Button
          onClick={()=>setIsShown(false)}
          variant="primary">Manage Cookies</Button>

          <Button
          onClick={()=>setIsShown(false)}
          variant="danger">Decline all</Button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
