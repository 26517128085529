import React, { useContext, useEffect, useState } from 'react'
import { CustomButton, Header, Order, SmallScreenHeader } from '../../../components';
import Lottie from 'lottie-react';

import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { APPDATAURLS } from '../../../utils/APPADATAURLS';
import { Button } from 'react-bootstrap';
import { Auth } from '../..';
import "./addresses.css";

const Orders = () => {
  const navigate = useNavigate();
  const [userData,setUserData] = useContext(UserContext);
  const [data,setData] = useState({
    loading:false,
    error:false,
    message:"",
    results:[],
  });

  const [refresh,setRefresh] = useState(false);

  const [activeTab,setActiveTab] = useState(0);

  const getOrders = ()=>{
    setData({
      loading:true,
      error:false,
      message:"",
      results:[],
    });
    fetch(`${APPDATAURLS.userOrders}${userData.data.id}`,{
          method:"GET"
      }).
      then(req=>req.json()).
      then(response=>{
        console.log(response);
      setData({
        loading:false,
        error:response.error,
        message:response.message,
        results:response.data,
      });
      
      }).catch(e=>{
          setData({
          loading:false,
          error:true,
          message:e.message,
          results:[],
        });
      })
  }

  useEffect(()=>{
    getOrders();
  },[userData.loggedIn,refresh])
  return (
    <>
    {
      userData.loggedIn?
      <div className='body' >
      <Header/>
      <div className='addresses-wrapper'>
        <SmallScreenHeader title={"Orders"}/>

        {
          data.error?
          <div>
            An error occurred!
          </div>:
          data.loading?
          <Lottie 
          style={{width:50,height:50}} 
          loop 
          animationData={require("../../../assets/lottie_animations/circle_progress.json")} />:
          data.results.length>0?
          <div
            className='addresses-holder'
            style={{
              display:"flex",
              flexDirection:"column",
              gap:10,
            }}
          >
            <div
            style={{
              width:"100%",
              display:"flex",
              flexDirection:"row",
              gap:10,
              marginTop:10
            }}
            >
              <CustomButton  
                width={100}
                backgroundColor={activeTab==0?"var(--app-color)":"#f5f5f5"}
                color={activeTab==0?"#ffffff":"#000000"}
                pressAction={()=>setActiveTab(0)}
                title={"All"} >All</CustomButton>
              <CustomButton  
                width={100}
                backgroundColor={activeTab==1?"var(--app-color)":"#f5f5f5"}
                color={activeTab==1?"#ffffff":"#000000"}
                pressAction={()=>setActiveTab(1)}
                title={"Pending"}>Pending</CustomButton>
              <CustomButton  
                width={100}
                backgroundColor={activeTab==2?"var(--app-color)":"#f5f5f5"}
                color={activeTab==2?"#ffffff":"#000000"}
                pressAction={()=>setActiveTab(2)}
                title={"Canceled"}>Canceled</CustomButton>
            </div>
            {
              activeTab==0?
              data.results.map((order,index)=>{
                return <Order order={order} refresh={refresh} setRefresh={setRefresh} />;
              }):
              activeTab==1?
              data.results.filter((item)=>item.isCanceled==0 && item.status=="Pending").map((order,index)=>{
                return <Order order={order} refresh={refresh} setRefresh={setRefresh} />;
              }):
              data.results.filter((item)=>item.isCanceled==1).map((order,index)=>{
                return <Order order={order} refresh={refresh} setRefresh={setRefresh} />;
              })

            }
          </div>:
          <div>
            No orders found
          </div>
        }
      </div>
    </div>:
    <Auth.LoginPage/>
    }
    
    </>
  )
}

export default Orders;