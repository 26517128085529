import React, { useState } from "react";

function useAppCookies() {
  const [cookies, setCookies] = useState({});
  const addCookie = (name, value, expiryDays) => {
    let date = new Date();
    let expiresIn = date.setTime(
      date.getTime() + expiryDays * 24 * 3600 * 1000
    );
    document.cookie = `
        ${name}=${value};
        SameSite=None;
        Secure;
        expires=${date.toUTCString()}
        `;
  };

  const getCookie = (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const deleteCookie = (name) => {};

  const getCookies = () => {
    return document.cookie;
  };

  return { ...cookies, addCookie, getCookies, getCookie, deleteCookie };
}

export default useAppCookies;
