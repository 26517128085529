import React from "react";
import { Header, SmallScreenHeader } from "../../components";
import { Card } from "react-bootstrap";
import "./help.css";

const Help = () => {
  return (
    <div className="body">
      <Header />
      <div className="body-wrapper">
        <SmallScreenHeader backButtonShown={true} title={"Help"} />
        <header>
          <h1>Help Center</h1>
          <p>Find answers to your questions and get support.</p>
        </header>
        <main className="main_help">
          <section>
            <h2>Account & Registration</h2>
            <ul>
              <li>
                <a className="a" href="#">Creating an Account</a>
              </li>
              <li>
                <a className="a" href="#">Logging In/Out</a>
              </li>
              <li>
                <a className="a" href="#">Managing Account Information</a>
              </li>
              <li>
                <a className="a" href="#">Password Reset</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Browsing & Searching Products</h2>
            <ul>
              <li>
                <a className="a" href="#">Searching for Products</a>
              </li>
              <li>
                <a className="a" href="#">Using Filters and Categories</a>
              </li>
              <li>
                <a className="a" href="#">Product Pages</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Placing an Order</h2>
            <ul>
              <li>
                <a className="a" href="#">Adding Products to the Cart</a>
              </li>
              <li>
                <a className="a" href="#">Checking Out</a>
              </li>
              <li>
                <a className="a" href="#">Payment Options</a>
              </li>
              <li>
                <a className="a" href="#">Applying Discounts & Coupons</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Shipping & Delivery</h2>
            <ul>
              <li>
                <a className="a" href="#">Shipping Methods</a>
              </li>
              <li>
                <a className="a" href="#">Tracking Your Order</a>
              </li>
              <li>
                <a className="a" href="#">Shipping Costs</a>
              </li>
              <li>
                <a className="a" href="#">International Shipping</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Returns & Refunds</h2>
            <ul>
              <li>
                <a className="a" href="#">Return Policy</a>
              </li>
              <li>
                <a className="a" href="#">How to Return an Item</a>
              </li>
              <li>
                <a className="a" href="#">Refund Process</a>
              </li>
              <li>
                <a className="a" href="#">Exchange Policy</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Order Issues</h2>
            <ul>
              <li>
                <a className="a" href="#">Order Status</a>
              </li>
              <li>
                <a className="a" href="#">Missing or Damaged Items</a>
              </li>
              <li>
                <a className="a" href="#">Order Cancellation</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Payment & Billing</h2>
            <ul>
              <li>
                <a className="a" href="#">Accepted Payment Methods</a>
              </li>
              <li>
                <a className="a" href="#">Payment Issues</a>
              </li>
              <li>
                <a className="a" href="#">Billing Information</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Technical Support</h2>
            <ul>
              <li>
                <a className="a" href="#">Website Navigation Issues</a>
              </li>
              <li>
                <a className="a" href="#">Browser Compatibility</a>
              </li>
              <li>
                <a className="a" href="#">Mobile App Support</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Contact Us</h2>
            <ul>
              <li>
                <a className="a" href="#">Customer Support</a>
              </li>
              <li>
                <a className="a" href="#">FAQ Section</a>
              </li>
              <li>
                <a className="a" href="#">Feedback & Suggestions</a>
              </li>
            </ul>
          </section>

          <section>
            <h2>Privacy & Security</h2>
            <ul>
              <li>
                <a className="a" href="#">Privacy Policy</a>
              </li>
              <li>
                <a className="a" href="#">Secure Shopping</a>
              </li>
            </ul>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Help;
