import React, { useEffect, useState } from "react";
import "./index.css";
import { BottomTabs, Header, SmallScreenHeader } from "../../components";
import { APIURL, BASEURL } from "../../utils/APPADATAURLS";
import Search from "../../components/Search";
import { HomeCategoriesLoading } from "../../components/Loading";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";

const SearchScreen = () => {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState({
    loading: false,
    results: [],
    error: false,
    message: "",
  });
  const search = (text) => {
    if (text.length < 2) {
      setData({
        loading: false,
        results: [],
        error: false,
        message: "",
      });
      return false;
    }
    setData({
      loading: true,
      results: [],
      error: false,
      message: "",
    });
    let formData = new FormData();
    formData.append("search", "search");
    formData.append("searchQuery", text);
    fetch(APIURL, {
      method: "POST",
      body: formData,
    })
      .then((req) => req.json())
      .then((res) => {
        setData({
          loading: false,
          results: res.data,
          error: res.error,
          message: res.message,
        });
      })
      .catch((e) => {
        setData({
          loading: false,
          results: [],
          error: true,
          message: "An error occurred. Please try again.",
        });
      });
  };
  const navigate = useNavigate();

  console.log([1, 2, 3].concat(["1", "a", "c"]));

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (window.innerWidth > 768) {
        navigate("/");
      }
    });
  }, window.innerWidth);
  return (
    <div className="body">
      <Header />
      <div className="body-wrapper">
        <div className="small-screen-search">
          <input
            autoFocus={true}
            onChange={(e) => {
              search(e.target.value);
              setSearchText(e.target.value);
            }}
            type={"search"}
            placeholder={"Search by product name, category, company..."}
            className={"search-input"}
          />
          <FaSearch
            className="search-icon"
            size={20}
            fill={"var(--app-color)"}
          />
        </div>
      </div>
      <div className="results">
        {data.error ? (
          <div>{data.message}</div>
        ) : data.loading ? (
          <HomeCategoriesLoading />
        ) : data.results?.items ? (
          <>
            {data.results.items.map((product, index) => {
              return (
                <Search
                  key={index}
                  product={product}
                  setSearchText={setSearchText}
                />
              );
            })}

            {data.results.categories.map((category, index) => {
              return (
                <div
                  onClick={(e) => {
                    navigate(
                      `/shop/${category.name.split("/").join(".")}/${
                        category.id
                      }`
                    );

                    setSearchText("");
                  }}
                  className="search-result"
                >
                  <img src={`${BASEURL}/${category.categoryImage}`} />
                  <div className="name-and-price">
                    <label>
                      {category.name?.length > 55
                        ? `${category.name?.substring(0, 55)}...`
                        : category.name}
                    </label>
                  </div>
                </div>
              );
            })}

            {data.results.companies.map((company, index) => {
              return (
                <div
                  onClick={(e) => {
                    navigate(
                      `/company/${company.name?.split("/").join(".")}/${
                        company.id
                      }`
                    );

                    setSearchText("");
                  }}
                  className="search-result"
                >
                  <img src={`${BASEURL}/${company.image}`} />
                  <div className="name-and-price">
                    <label>
                      {company.name?.length > 55
                        ? `${company.name.substring(0, 55)}...`
                        : company.name}
                    </label>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div>
            <label>
              {searchText.length > 2
                ? `No results found matching ${searchText}`
                : ""}
            </label>
          </div>
        )}
      </div>
      <BottomTabs />
    </div>
  );
};

export default SearchScreen;
