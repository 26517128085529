import React from "react";
import { FiStar } from "react-icons/fi";
import "./index.css";

const ratings = [
    {
        name:"1",
        total:2
    },
    {
        name:"2",
        total:100
    },
    {
        name:"3",
        total:2
    },
    {
        name:"4",
        total:190
    },
    {
        name:"5",
        total:400
    },
]
const TopBarRatings = (data)=>{
    return(
        <div
        className="ratings-top-bar"
        >
            {
                ratings.map((rating,index)=>{
                    return(
                        <div className="rating-top-bar"
                        style={{
                            marginBottom:10
                        }}
                        >
                            {
                                [1,2,3,4,5].map((_,index)=>{
                                    return <FiStar 
                                        color={(index+1)<=rating.name?'var(--app-color)':'#b9b4b4'}  
                                        fill={(index+1)<=rating.name?'var(--app-color)':'#b9b4b4'} 
                                        size={15} 
                                        />
                                })
                            }
                            {/* <label>({rating.total})</label> */}
                        </div>
                        )
                    })
            }
        </div>
    )
}

export default TopBarRatings;